// Task Kanban

.task-kanban-container {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  .task-card {
    width: 20rem;
  }

  .task-list {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      line-height: 110px;
      width: 100%;
      text-align: center;
      font-weight: 600;
    }
  }
  .task-kanban {
    position: relative;

    .card {
      white-space: normal;
      margin-bottom: 1rem;
    }
    > div,
    .gu-mirror {
      margin: 0px;
      padding: 0px;
      min-height: 100px;
      width: 100%;
      transition: opacity 0.4s ease-in-out;
      cursor: pointer;
    }

    // .tasks-container{
    //   min-height: 100px!important;
    // }
  }
}
