// Avatar

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

// Avatar Size

.avatar-xs {
  width: $avatar-size-xs;
  height: $avatar-size-xs;
}
.avatar-sm {
  width: $avatar-size-sm;
  height: $avatar-size-sm;
}
.avatar-md {
  width: $avatar-size-md;
  height: $avatar-size-md;
}

.avatar-lg {
  width: $avatar-size-lg;
  height: $avatar-size-lg;
}
.avatar-xl {
  width: $avatar-size-xl;
  height: $avatar-size-xl;
}

.avatar-xxl {
  width: $avatar-size-xxl;
  height: $avatar-size-xxl;
}

// Avatar img

.avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

// Avatar indicators
.avatar-offline:before {
  background-color: var(--#{$prefix}gray-400);
}
.avatar-online:before {
  background-color: var(--#{$prefix}success);
}
.avatar-away:before {
  background-color: var(--#{$prefix}warning);
}
.avatar-busy:before {
  background-color: var(--#{$prefix}danger);
}
.avatar-info:before {
  background-color: var(--#{$prefix}info);
}
.avatar-indicators:before {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 5%;
  width: 30%;
  height: 30%;
  border-radius: 50%;
  border: 2px solid var(--#{$prefix}white);
  display: table;
}

// Avatar intials

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-transform: uppercase;
}

// Color varient

.avatar-primary .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}primary);
}
.avatar-secondary .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}secondary);
}
.avatar-success .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}success);
}
.avatar-warning .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}warning);
}
.avatar-info .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}info);
}
.avatar-danger .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}danger);
}
.avatar-light .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}light);
}
.avatar-dark .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}dark);
}
.avatar-primary-soft .avatar-initials {
  color: var(--#{$prefix}primary);
  background-color: $light-primary;
}
.avatar-secondary-soft .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}secondary);
}
.avatar-success-soft .avatar-initials {
  color: var(--#{$prefix}success);
  background-color: var(--#{$prefix}light-success);
}
.avatar-warning-soft .avatar-initials {
  color: var(--#{$prefix}warning);
  background-color: var(--#{$prefix}light-warning);
}
.avatar-info-soft .avatar-initials {
  color: var(--#{$prefix}info);
  background-color: var(--#{$prefix}light-info);
}
.avatar-danger-soft .avatar-initials {
  color: var(--#{$prefix}danger);
  background-color: var(--#{$prefix}light-danger);
}
.avatar-dark-soft .avatar-initials {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}light-dark);
}
.avatar-light-soft .avatar-initials {
  color: var(--#{$prefix}dark);
  background-color: var(--#{$prefix}light);
}

// Avatar Group

.avatar-group .avatar + .avatar {
  margin-left: -1.2rem;
}
.avatar-group .avatar:hover {
  z-index: 2;
}

// Avatar border

.avatar-group img,
.avatar-group .avatar .avatar-initials {
  border: 3px solid var(--#{$prefix}white);
}

// Image aspect ratio 4:3

.img-4by3-xxl {
  width: $img-4by3-xxl;
}
.img-4by3-xl {
  width: $img-4by3-xl;
}
.img-4by3-lg {
  width: $img-4by3-lg;
}
.img-4by3-md {
  width: $img-4by3-md;
}
.img-4by3-sm {
  width: $img-4by3-sm;
}
.img-4by3-xs {
  width: $img-4by3-xs;
}
