// Odometer

.odometer.odometer-auto-theme {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  font-family: $font-family-base;
  line-height: 1.1em;
  font-size: 3rem;
  .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    .odometer-digit-spacer {
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      *zoom: 1;
      *display: inline;
      visibility: hidden;
    }
    .odometer-digit-inner {
      text-align: left;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
    .odometer-ribbon {
      display: block;
    }
    .odometer-ribbon-inner {
      display: block;
      -webkit-backface-visibility: hidden;
    }
    .odometer-value {
      display: block;
      -webkit-transform: translateZ(0);
    }
    .odometer-value.odometer-last-value {
      position: absolute;
    }
  }
  .odometer-value {
    text-align: center;
    color:  var(--#{$prefix}gray-900);
    font-weight: 600;    
  }
}

.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  font-family: $font-family-base;
  line-height: 1.1em;
  .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    .odometer-digit-spacer {
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      *zoom: 1;
      *display: inline;
      visibility: hidden;
    }
    .odometer-digit-inner {
      text-align: left;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
    .odometer-ribbon {
      display: block;
    }
    .odometer-ribbon-inner {
      display: block;
      -webkit-backface-visibility: hidden;
    }
    .odometer-value {
      display: block;
      -webkit-transform: translateZ(0);
    }
    .odometer-value.odometer-last-value {
      position: absolute;
    }
  }
  .odometer-value {
    text-align: center;
    color:  var(--#{$prefix}gray-900);
    font-weight: 600;
  }
}

.odometer.odometer-auto-theme.odometer-animating-up {
  .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
  }
}

.odometer.odometer-theme-default.odometer-animating-up {
  .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
  }
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating {
  .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.odometer.odometer-theme-default.odometer-animating-up.odometer-animating {
  .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.odometer.odometer-auto-theme.odometer-animating-down {
  .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.odometer.odometer-theme-default.odometer-animating-down {
  .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating {
  .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.odometer.odometer-theme-default.odometer-animating-down.odometer-animating {
  .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
